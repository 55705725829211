import { initializeApp } from 'firebase/app';
import {
  getFirestore, initializeFirestore, connectFirestoreEmulator, collection, onSnapshot, doc, query, where, addDoc, collectionGroup, getDoc, orderBy,
} from 'firebase/firestore';
import { getAuth, signInWithCustomToken, connectAuthEmulator } from 'firebase/auth';
import {
  getMessaging, getToken, isSupported,
} from 'firebase/messaging';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { isDev } from '@/util/utils';
import { authState$, notificationsEnabled$ } from '@/util/subjects';

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const app = initializeApp(config);
initializeFirestore(app, { experimentalAutoDetectLongPolling: true });
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west3');

const useEmulators = isDev();

if (useEmulators) {
  console.log('=== Using emulators instead of production Firebase backend ===');
  connectFirestoreEmulator(db, 'localhost', 8484);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const getCupBackendId = async () => getDoc(doc(db, `cups/${process.env.VUE_APP_CUP_ID}`)).then((snap) => snap.data()?.id);

const getCollectionObserver = (collectionName, observer) => onSnapshot(collection(db, collectionName), observer);

const getNearLiveCollectionObserver = (match, observer) => onSnapshot(
  query(
    collection(db, `nearLive/cups/${process.env.VUE_APP_CUP_ID}`),
    where('state', '==', 'published'),
    where('match', '==', match),
    orderBy('captured', 'desc'),
  ),
  observer,
);

const getNearLiveCollectionObserverByDay = (matchDay, observer) => onSnapshot(
  query(
    collection(db, `nearLive/cups/${process.env.VUE_APP_CUP_ID}`),
    where('state', '==', 'published'),
    where('matchDay', '==', matchDay),
    orderBy('captured', 'desc'),
  ),
  observer,
);

const getPmaCollectionObserver = (observer) => {
  let unsubscribe;

  getCupBackendId().then((cupId) => {
    unsubscribe = onSnapshot(
      query(
        collectionGroup(db, 'postings'),
        where('cupId', '==', cupId),
        where('status', '==', 'review_approved'),
        orderBy('created', 'desc'),
      ),
      observer,
    );
  });

  return () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
};

const getDocumentObserver = (path, observer) => onSnapshot(doc(db, path), observer);

const getSignedDownloadUrl = (key) => httpsCallable(functions, 'getSignedDownloadUrl')({ key });

const setupFCM = async (cb) => {
  if (await isSupported()) {
    const messaging = getMessaging(app);

    getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          cb(currentToken);
        }
      }).catch(() => {
        // noop
      });
  }
};

auth.onAuthStateChanged((user) => {
  if (user) {
    authState$.next(user);

    isSupported().then((notificationsSupported) => {
      // Trigger FCM setup if we already have the notification permission
      if (notificationsSupported && Notification.permission === 'granted') {
        notificationsEnabled$.next(true);
      }
    });
  } else {
    authState$.next(null);
  }
});

auth.onIdTokenChanged((user) => {
  if (user) {
    authState$.next(user);
  } else {
    authState$.next(null);
  }
});

const login = async (token) => signInWithCustomToken(auth, token);
const logout = async () => auth.signOut();

const addUserLog = (message) => {
  if (!auth.currentUser) {
    return;
  }

  addDoc(
    collection(db, `users/${auth.currentUser.uid}/logs`),
    {
      created: (new Date()).toUTCString(),
      message,
    },
  ).catch(() => {
    // noop
  });
};

export {
  getCollectionObserver,
  getDocumentObserver,
  login,
  logout,
  getNearLiveCollectionObserver,
  getNearLiveCollectionObserverByDay,
  addUserLog,
  getPmaCollectionObserver,
  getSignedDownloadUrl,
  setupFCM,
  auth,
};

<template>
  <div class="smp-activeFiltersBar flex flex-wrap mxn-1">
    <div
      class="smp-activeFilterTag m1"
      v-for="filter in activeFilters"
      :key="filter.value"
      @click.prevent="removeFilter(filter)">
      <template v-if="filter.icon && Array.isArray(filter.icon)">
        <i
          class="mr2">
          <fa-icon :icon="filter.icon" />
        </i>
      </template>
      <template v-if="filter.icon && typeof filter.icon === 'string'">
        <span
          class="mr2"
          v-html="filter.icon" />
      </template>

      {{ filter.label }}

      <i class="ml2">
        <fa-icon :icon="['fas', 'times']" />
      </i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['activeFilters']),
  },
  methods: {
    removeFilter(filter) {
      switch (filter.filterKey) {
      case 'categories':
        this.$store.dispatch('toggleCategory', filter.value);
        break;
      case 'types':
        this.$store.dispatch('toggleType', filter.value);
        break;
      case 'teams':
        this.$store.dispatch('toggleTeam', filter.value);
        break;
      case 'date':
        this.$store.dispatch('setDate', null);
        break;
      default:
        this.$emit('removeFilter', filter);
      }
    },
  },
};
</script>

<style lang="less">
  @import (reference) "~@/styles/base";
  .smp-activeFilterTag {
    background: @color-primary;
    color: @color-text-for-primary;
    padding: @space-1 @space-2;
    .ms(-1);
    border-radius: @defaultBorderRadius;
    cursor: pointer;
  }
</style>

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const state = {
  postings: [],
  match: null,
  matchDay: null,
  postingUnsubscribeFn: null,
  activePosting: null,
  viewMode: 'grid',
};

export {
  getters, actions, mutations, state,
};

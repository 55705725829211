<template>
  <div class="smp-localeMenu">
    <single-select-filter
      ref="selectFilter"
      :options="localeOptions"
      filter-key="locale"
      @input="changeLocale"
      :icon="['fas', 'language']" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SingleSelectFilter from '@/components/SingleSelectFilter.vue';
import { currentLocale$ } from '@/util/i18n';

export default {
  components: { SingleSelectFilter },
  computed: {
    ...mapState(['cupProfile', 'currentLocale']),
    localeOptions() {
      if (this.cupProfile) {
        return this.cupProfile.locales.map((code) => ({
          value: code,
          label: this.$t(`locales.${code}`),
          active: code === this.currentLocale,
        }));
      }

      return null;
    },
  },
  methods: {
    ...mapActions(['setLocale']),
    changeLocale(option) {
      currentLocale$.next(option.value);
      this.$refs.selectFilter.close();
    },
  },
};
</script>

export default {
  isReady(state, getters, rootState) {
    return !!rootState.cupProfile;
  },
  availableMatches(state, getters, rootState, rootGetters) {
    return rootGetters.matches;
  },
  currentMatchesRange(state, getters, rootState) {
    return rootState.cupProfile?.currentMatchesRange;
  },
  nearLiveGroupMode(state, getters, rootState) {
    return rootState.cupProfile?.nearLive?.groupMode;
  },
  inMatchMode(state, getters) {
    return !getters.nearLiveGroupMode || getters.nearLiveGroupMode === 'match';
  },
  inDayMode(state, getters) {
    return getters.nearLiveGroupMode === 'day';
  },
};
